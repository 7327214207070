.menu {
}

.addCircleMobile {
    position: fixed;
    width: 5rem;
    height: 5rem;
    top: 0;
    right: 0rem;
    z-index: 4000;
    transition: all 0.5s cubic-bezier(0.26, 1.45, 0.87, -0.49);

    .circle_cover {
        position: absolute;
        top: 0;
        right: 0;
        width: 6rem;
        height: 6rem;
        z-index: 9000;
        transform: scale(1.4);

        &:hover {
            cursor: pointer;
        }
    }
}

.menu_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;

    top: 0;
    bottom: 0;
    right: -25rem;
    z-index: 5000;
    font-size: 8rem;
    transition: all 0.5s cubic-bezier(0.26, 1.45, 0.87, -0.49);
    overflow-y: scroll;
    background-color: var(--fifth_color);

    @media screen and (min-width: 1000px) {
    }

    .menuIcons {
        display: inline;
        color: var(--second_color);
        transition: all 0.2s;
        text-decoration: none;

        &:hover {
            transform: scale(1.1);
            color: var(--first_color);
        }
    }
}

.menu_desktop {
    position: absolute;
    top: 5rem;
    left: 50%;
    right: 0;
    transform: translateX(-7.5rem);
    z-index: 5000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 5rem;

    a {
        width: 100%;
    }
    &:after {
        content: " ";
        position: absolute;
        top: 8rem;
        width: 65rem;
        height: 0.1rem;
        background-color: var(--first_color);
        border-radius: 0.5rem;
        opacity: 0.5;
        transition: all 0.5s cubic-bezier(0.26, 1.45, 0.87, -0.49);
    }
    .menuIcons {
        display: inline-block;
        width: fit-content;
        color: var(--first_color);
        margin: 2.5rem;
        list-style: none;

        &:hover {
            color: var(--fifth_color);
            transform: scale(1.2);
            transition: all 0.3s;
        }

        &:focus {
            color: var(--first_color);
        }
    }
}
